<template>
  <CollectionDetails
    hide-footer
    hide-breadcrumbs
    :is-auth="isAuth"
    :is-shared-collection="true" />
</template>
<script>
import CollectionDetails from '@/components/CollectionDetails';
import { Auth } from 'aws-amplify';
export default {
  name: 'SharedCollectionDetails',
  components: {
    CollectionDetails,
  },
  data() {
    return {
      isAuth: true,
    };
  },
  created() {
    this.checkAuth();
  },
  methods: {
    async checkAuth() {
      try {
        const response = await Auth.currentAuthenticatedUser();
        if (response?.signInUserSession) this.isAuth = true;
      } catch {
        this.isAuth = false;
      }
    },
  },
};
</script>
<style scoped>
</style>
